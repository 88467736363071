interface ICountryRequirements {
    id: number;
    name: string;
    simplified_name: string;
    alpha2: string;
    alpha3: string;
    number: string;
    country_code: string;
    continent: string;
    region: string;
    subregion: string;
    currency_code: string;
    currency_symbol: string;
    display_currency: string;
    requirements: {
        requires_state: boolean;
        has_postal_code: boolean;
        postal_code_regex: string | null;
        postal_code_examples: string | null;
        postal_code_mandatory_from_origin: boolean | null | Number[];
        consignee_tax_id_required: boolean;
        consignee_tax_id_required_umbrella_names: string[];
    };
}

export const checkIsZipCodeRequired = (country_code: string): boolean => {
    if (!country_code) {
        return true;
    }
    const foundCountry: ICountryRequirements | undefined = CourierRequirements?.find(
        (item: ICountryRequirements) => item?.alpha2 === country_code
    );
    const requirements = foundCountry?.requirements;

    // Return false if either requires_state or has_postal_code is false, otherwise return true
    return !!(requirements?.requires_state && requirements?.has_postal_code);
};



export const CourierRequirements: ICountryRequirements[] = [
    {
        "id": 1,
        "name": "Andorra",
        "simplified_name": "Andorra",
        "alpha2": "AD",
        "alpha3": "AND",
        "number": "020",
        "country_code": "376",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 2,
        "name": "United Arab Emirates",
        "simplified_name": "United Arab Emirates",
        "alpha2": "AE",
        "alpha3": "ARE",
        "number": "784",
        "country_code": "971",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "AED",
        "currency_symbol": "",
        "display_currency": "AED",
        "requirements": {
            "requires_state": true,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 3,
        "name": "Afghanistan",
        "simplified_name": "Afghanistan",
        "alpha2": "AF",
        "alpha3": "AFG",
        "number": "004",
        "country_code": "93",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Southern Asia",
        "currency_code": "AFN",
        "currency_symbol": "؋",
        "display_currency": "AFN",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 4,
        "name": "Antigua and Barbuda",
        "simplified_name": "Antigua and Barbuda",
        "alpha2": "AG",
        "alpha3": "ATG",
        "number": "028",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "XCD",
        "currency_symbol": "$",
        "display_currency": "XCD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 5,
        "name": "Anguilla",
        "simplified_name": "Anguilla",
        "alpha2": "AI",
        "alpha3": "AIA",
        "number": "660",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "XCD",
        "currency_symbol": "$",
        "display_currency": "XCD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 6,
        "name": "Albania",
        "simplified_name": "Albania",
        "alpha2": "AL",
        "alpha3": "ALB",
        "number": "008",
        "country_code": "355",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "ALL",
        "currency_symbol": "LEK",
        "display_currency": "ALL",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 7,
        "name": "Armenia",
        "simplified_name": "Armenia",
        "alpha2": "AM",
        "alpha3": "ARM",
        "number": "051",
        "country_code": "374",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "AMD",
        "currency_symbol": "",
        "display_currency": "AMD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 8,
        "name": "Netherlands Antilles",
        "simplified_name": "Netherlands Antilles",
        "alpha2": "AN",
        "alpha3": "ANT",
        "number": "530",
        "country_code": "599",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "ANG",
        "currency_symbol": "ƒ",
        "display_currency": "ANG",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 9,
        "name": "Angola",
        "simplified_name": "Angola",
        "alpha2": "AO",
        "alpha3": "AGO",
        "number": "024",
        "country_code": "244",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Middle Africa",
        "currency_code": "AOA",
        "currency_symbol": "",
        "display_currency": "AOA",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 10,
        "name": "Antarctica",
        "simplified_name": "Antarctica",
        "alpha2": "AQ",
        "alpha3": "ATA",
        "number": "010",
        "country_code": "672",
        "continent": "Others",
        "region": "",
        "subregion": "",
        "currency_code": "",
        "currency_symbol": "",
        "display_currency": "",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 11,
        "name": "Argentina",
        "simplified_name": "Argentina",
        "alpha2": "AR",
        "alpha3": "ARG",
        "number": "032",
        "country_code": "54",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "ARS",
        "currency_symbol": "$",
        "display_currency": "ARS",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 12,
        "name": "American Samoa",
        "simplified_name": "American Samoa",
        "alpha2": "AS",
        "alpha3": "ASM",
        "number": "016",
        "country_code": "1",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Polynesia",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 13,
        "name": "Austria",
        "simplified_name": "Austria",
        "alpha2": "AT",
        "alpha3": "AUT",
        "number": "040",
        "country_code": "43",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Western Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 14,
        "name": "Australia",
        "simplified_name": "Australia",
        "alpha2": "AU",
        "alpha3": "AUS",
        "number": "036",
        "country_code": "61",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "currency_code": "AUD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{4}$",
            "postal_code_examples": "9999",
            "postal_code_mandatory_from_origin": [
                14
            ],
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 15,
        "name": "Aruba",
        "simplified_name": "Aruba",
        "alpha2": "AW",
        "alpha3": "ABW",
        "number": "533",
        "country_code": "297",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "AWG",
        "currency_symbol": "ƒ",
        "display_currency": "AWG",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 17,
        "name": "Azerbaijan",
        "simplified_name": "Azerbaijan",
        "alpha2": "AZ",
        "alpha3": "AZE",
        "number": "031",
        "country_code": "994",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "AZN",
        "currency_symbol": "ман",
        "display_currency": "AZN",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 18,
        "name": "Bosnia and Herzegovina",
        "simplified_name": "Bosnia and Herzegovina",
        "alpha2": "BA",
        "alpha3": "BIH",
        "number": "070",
        "country_code": "387",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "BAM",
        "currency_symbol": "KM",
        "display_currency": "BAM",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 19,
        "name": "Barbados",
        "simplified_name": "Barbados",
        "alpha2": "BB",
        "alpha3": "BRB",
        "number": "052",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "BBD",
        "currency_symbol": "$",
        "display_currency": "BBD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 20,
        "name": "Bangladesh",
        "simplified_name": "Bangladesh",
        "alpha2": "BD",
        "alpha3": "BGD",
        "number": "050",
        "country_code": "880",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Southern Asia",
        "currency_code": "BDT",
        "currency_symbol": "",
        "display_currency": "BDT",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 21,
        "name": "Belgium",
        "simplified_name": "Belgium",
        "alpha2": "BE",
        "alpha3": "BEL",
        "number": "056",
        "country_code": "32",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Western Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 22,
        "name": "Burkina Faso",
        "simplified_name": "Burkina Faso",
        "alpha2": "BF",
        "alpha3": "BFA",
        "number": "854",
        "country_code": "226",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "XOF",
        "currency_symbol": "",
        "display_currency": "XOF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 23,
        "name": "Bulgaria",
        "simplified_name": "Bulgaria",
        "alpha2": "BG",
        "alpha3": "BGR",
        "number": "100",
        "country_code": "359",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "currency_code": "BGN",
        "currency_symbol": "лв",
        "display_currency": "BGN",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 24,
        "name": "Bahrain",
        "simplified_name": "Bahrain",
        "alpha2": "BH",
        "alpha3": "BHR",
        "number": "048",
        "country_code": "973",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "BHD",
        "currency_symbol": "",
        "display_currency": "BHD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 25,
        "name": "Burundi",
        "simplified_name": "Burundi",
        "alpha2": "BI",
        "alpha3": "BDI",
        "number": "108",
        "country_code": "257",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "BIF",
        "currency_symbol": "",
        "display_currency": "BIF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 26,
        "name": "Benin",
        "simplified_name": "Benin",
        "alpha2": "BJ",
        "alpha3": "BEN",
        "number": "204",
        "country_code": "229",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "XOF",
        "currency_symbol": "",
        "display_currency": "XOF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 27,
        "name": "Saint Barthélemy",
        "simplified_name": "Saint Barthélemy",
        "alpha2": "BL",
        "alpha3": "BLM",
        "number": "652",
        "country_code": "590",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 28,
        "name": "Bermuda",
        "simplified_name": "Bermuda",
        "alpha2": "BM",
        "alpha3": "BMU",
        "number": "060",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Northern America",
        "currency_code": "BMD",
        "currency_symbol": "$",
        "display_currency": "BMD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 29,
        "name": "Brunei Darussalam",
        "simplified_name": "Brunei Darussalam",
        "alpha2": "BN",
        "alpha3": "BRN",
        "number": "096",
        "country_code": "673",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "currency_code": "BND",
        "currency_symbol": "$",
        "display_currency": "BND",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 30,
        "name": "Bolivia",
        "simplified_name": "Bolivia",
        "alpha2": "BO",
        "alpha3": "BOL",
        "number": "068",
        "country_code": "591",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "BOB",
        "currency_symbol": "$b",
        "display_currency": "BOB",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 31,
        "name": "Bonaire, Sint Eustatius and Saba",
        "simplified_name": "Bonaire, Sint Eustatius and Saba",
        "alpha2": "BQ",
        "alpha3": "BES",
        "number": "535",
        "country_code": "599",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 32,
        "name": "Brazil",
        "simplified_name": "Brazil",
        "alpha2": "BR",
        "alpha3": "BRA",
        "number": "076",
        "country_code": "55",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "BRL",
        "currency_symbol": "R$",
        "display_currency": "BRL",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": true,
            "consignee_tax_id_required_umbrella_names": [
                "APC",
                "SkyPostal"
            ]
        }
    },
    {
        "id": 33,
        "name": "Bahamas",
        "simplified_name": "Bahamas",
        "alpha2": "BS",
        "alpha3": "BHS",
        "number": "044",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "BSD",
        "currency_symbol": "$",
        "display_currency": "BSD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 34,
        "name": "Bhutan",
        "simplified_name": "Bhutan",
        "alpha2": "BT",
        "alpha3": "BTN",
        "number": "064",
        "country_code": "975",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Southern Asia",
        "currency_code": "BTN",
        "currency_symbol": "",
        "display_currency": "BTN",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 35,
        "name": "Bouvet Island",
        "simplified_name": "Bouvet Island",
        "alpha2": "BV",
        "alpha3": "BVT",
        "number": "074",
        "country_code": "",
        "continent": "Others",
        "region": "",
        "subregion": "",
        "currency_code": "NOK",
        "currency_symbol": "kr",
        "display_currency": "NOK",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 36,
        "name": "Botswana",
        "simplified_name": "Botswana",
        "alpha2": "BW",
        "alpha3": "BWA",
        "number": "072",
        "country_code": "267",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Southern Africa",
        "currency_code": "BWP",
        "currency_symbol": "P",
        "display_currency": "BWP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 37,
        "name": "Belarus",
        "simplified_name": "Belarus",
        "alpha2": "BY",
        "alpha3": "BLR",
        "number": "112",
        "country_code": "375",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "currency_code": "BYR",
        "currency_symbol": "p.",
        "display_currency": "BYR",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 38,
        "name": "Belize",
        "simplified_name": "Belize",
        "alpha2": "BZ",
        "alpha3": "BLZ",
        "number": "084",
        "country_code": "501",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Central America",
        "currency_code": "BZD",
        "currency_symbol": "BZ$",
        "display_currency": "BZD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 39,
        "name": "Canada",
        "simplified_name": "Canada",
        "alpha2": "CA",
        "alpha3": "CAN",
        "number": "124",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Northern America",
        "currency_code": "CAD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1} ?[0-9]{1}[a-zA-Z]{1}[0-9]{1}$",
            "postal_code_examples": "A9A 9A9",
            "postal_code_mandatory_from_origin": [
                39,
                234
            ],
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 40,
        "name": "Cocos (Keeling) Islands",
        "simplified_name": "Cocos (Keeling) Islands",
        "alpha2": "CC",
        "alpha3": "CCK",
        "number": "166",
        "country_code": "61",
        "continent": "Asia",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "currency_code": "AUD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 41,
        "name": "Congo, The Democratic Republic Of The",
        "simplified_name": "Congo, The Democratic Republic Of The",
        "alpha2": "CD",
        "alpha3": "COD",
        "number": "180",
        "country_code": "243",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Middle Africa",
        "currency_code": "",
        "currency_symbol": "",
        "display_currency": "",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 42,
        "name": "Central African Republic",
        "simplified_name": "Central African Republic",
        "alpha2": "CF",
        "alpha3": "CAF",
        "number": "140",
        "country_code": "236",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Middle Africa",
        "currency_code": "XAF",
        "currency_symbol": "",
        "display_currency": "XAF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 43,
        "name": "Congo",
        "simplified_name": "Congo",
        "alpha2": "CG",
        "alpha3": "COG",
        "number": "178",
        "country_code": "242",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Middle Africa",
        "currency_code": "",
        "currency_symbol": "",
        "display_currency": "",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 44,
        "name": "Switzerland",
        "simplified_name": "Switzerland",
        "alpha2": "CH",
        "alpha3": "CHE",
        "number": "756",
        "country_code": "41",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Western Europe",
        "currency_code": "CHF",
        "currency_symbol": "CHF",
        "display_currency": "CHF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{4}$",
            "postal_code_examples": "9999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 45,
        "name": "Côte D'Ivoire",
        "simplified_name": "Ivory Coast",
        "alpha2": "CI",
        "alpha3": "CIV",
        "number": "384",
        "country_code": "225",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "XOF",
        "currency_symbol": "",
        "display_currency": "XOF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 46,
        "name": "Cook Islands",
        "simplified_name": "Cook Islands",
        "alpha2": "CK",
        "alpha3": "COK",
        "number": "184",
        "country_code": "682",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Polynesia",
        "currency_code": "NZD",
        "currency_symbol": "$",
        "display_currency": "NZD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 47,
        "name": "Chile",
        "simplified_name": "Chile",
        "alpha2": "CL",
        "alpha3": "CHL",
        "number": "152",
        "country_code": "56",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "CLP",
        "currency_symbol": "$",
        "display_currency": "CLP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": true,
            "consignee_tax_id_required_umbrella_names": [
                "APC",
                "SkyPostal"
            ]
        }
    },
    {
        "id": 48,
        "name": "Cameroon",
        "simplified_name": "Cameroon",
        "alpha2": "CM",
        "alpha3": "CMR",
        "number": "120",
        "country_code": "237",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Middle Africa",
        "currency_code": "XAF",
        "currency_symbol": "",
        "display_currency": "XAF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 49,
        "name": "China",
        "simplified_name": "China",
        "alpha2": "CN",
        "alpha3": "CHN",
        "number": "156",
        "country_code": "86",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "currency_code": "CNY",
        "currency_symbol": "¥",
        "display_currency": "CNY",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{6}$",
            "postal_code_examples": "999999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": true,
            "consignee_tax_id_required_umbrella_names": [
                "Aramex",
                "SFExpress",
                "HKPost"
            ]
        }
    },
    {
        "id": 50,
        "name": "Colombia",
        "simplified_name": "Colombia",
        "alpha2": "CO",
        "alpha3": "COL",
        "number": "170",
        "country_code": "57",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "COP",
        "currency_symbol": "$",
        "display_currency": "COP",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 51,
        "name": "Costa Rica",
        "simplified_name": "Costa Rica",
        "alpha2": "CR",
        "alpha3": "CRI",
        "number": "188",
        "country_code": "506",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Central America",
        "currency_code": "CRC",
        "currency_symbol": "₡",
        "display_currency": "CRC",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 52,
        "name": "Cuba",
        "simplified_name": "Cuba",
        "alpha2": "CU",
        "alpha3": "CUB",
        "number": "192",
        "country_code": "53",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "CUP",
        "currency_symbol": "₱",
        "display_currency": "CUP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 53,
        "name": "Cape Verde",
        "simplified_name": "Cape Verde",
        "alpha2": "CV",
        "alpha3": "CPV",
        "number": "132",
        "country_code": "238",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "CVE",
        "currency_symbol": "",
        "display_currency": "CVE",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 54,
        "name": "Curaçao",
        "simplified_name": "Curaçao",
        "alpha2": "CW",
        "alpha3": "CUW",
        "number": "531",
        "country_code": "599",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "ANG",
        "currency_symbol": "ƒ",
        "display_currency": "ANG",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 55,
        "name": "Christmas Island",
        "simplified_name": "Christmas Island",
        "alpha2": "CX",
        "alpha3": "CXR",
        "number": "162",
        "country_code": "61",
        "continent": "Asia",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "currency_code": "AUD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 56,
        "name": "Cyprus",
        "simplified_name": "Cyprus",
        "alpha2": "CY",
        "alpha3": "CYP",
        "number": "196",
        "country_code": "357",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 57,
        "name": "Czech Republic",
        "simplified_name": "Czechia",
        "alpha2": "CZ",
        "alpha3": "CZE",
        "number": "203",
        "country_code": "420",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "currency_code": "CZK",
        "currency_symbol": "Kč",
        "display_currency": "CZK",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 58,
        "name": "Germany",
        "simplified_name": "Germany",
        "alpha2": "DE",
        "alpha3": "DEU",
        "number": "276",
        "country_code": "49",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Western Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{5}$",
            "postal_code_examples": "99999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 59,
        "name": "Djibouti",
        "simplified_name": "Djibouti",
        "alpha2": "DJ",
        "alpha3": "DJI",
        "number": "262",
        "country_code": "253",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "DJF",
        "currency_symbol": "",
        "display_currency": "DJF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 60,
        "name": "Denmark",
        "simplified_name": "Denmark",
        "alpha2": "DK",
        "alpha3": "DNK",
        "number": "208",
        "country_code": "45",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "DKK",
        "currency_symbol": "kr",
        "display_currency": "DKK",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 61,
        "name": "Dominica",
        "simplified_name": "Dominica",
        "alpha2": "DM",
        "alpha3": "DMA",
        "number": "212",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "XCD",
        "currency_symbol": "$",
        "display_currency": "XCD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 62,
        "name": "Dominican Republic",
        "simplified_name": "Dominican Republic",
        "alpha2": "DO",
        "alpha3": "DOM",
        "number": "214",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "DOP",
        "currency_symbol": "RD$",
        "display_currency": "DOP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 63,
        "name": "Algeria",
        "simplified_name": "Algeria",
        "alpha2": "DZ",
        "alpha3": "DZA",
        "number": "012",
        "country_code": "213",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Northern Africa",
        "currency_code": "DZD",
        "currency_symbol": "",
        "display_currency": "DZD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 64,
        "name": "Ecuador",
        "simplified_name": "Ecuador",
        "alpha2": "EC",
        "alpha3": "ECU",
        "number": "218",
        "country_code": "593",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 65,
        "name": "Estonia",
        "simplified_name": "Estonia",
        "alpha2": "EE",
        "alpha3": "EST",
        "number": "233",
        "country_code": "372",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 66,
        "name": "Egypt",
        "simplified_name": "Egypt",
        "alpha2": "EG",
        "alpha3": "EGY",
        "number": "818",
        "country_code": "20",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Northern Africa",
        "currency_code": "EGP",
        "currency_symbol": "£",
        "display_currency": "EGP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 67,
        "name": "Western Sahara",
        "simplified_name": "Western Sahara",
        "alpha2": "EH",
        "alpha3": "ESH",
        "number": "732",
        "country_code": "212",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Northern Africa",
        "currency_code": "MAD",
        "currency_symbol": "",
        "display_currency": "MAD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 68,
        "name": "Eritrea",
        "simplified_name": "Eritrea",
        "alpha2": "ER",
        "alpha3": "ERI",
        "number": "232",
        "country_code": "291",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "ETB",
        "currency_symbol": "",
        "display_currency": "ETB",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 69,
        "name": "Spain",
        "simplified_name": "Spain",
        "alpha2": "ES",
        "alpha3": "ESP",
        "number": "724",
        "country_code": "34",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{5}$",
            "postal_code_examples": "99999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 70,
        "name": "Ethiopia",
        "simplified_name": "Ethiopia",
        "alpha2": "ET",
        "alpha3": "ETH",
        "number": "231",
        "country_code": "251",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "ETB",
        "currency_symbol": "",
        "display_currency": "ETB",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 71,
        "name": "Finland",
        "simplified_name": "Finland",
        "alpha2": "FI",
        "alpha3": "FIN",
        "number": "246",
        "country_code": "358",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 72,
        "name": "Fiji",
        "simplified_name": "Fiji",
        "alpha2": "FJ",
        "alpha3": "FJI",
        "number": "242",
        "country_code": "679",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Melanesia",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 73,
        "name": "Falkland Islands (Malvinas)",
        "simplified_name": "Falkland Islands (Malvinas)",
        "alpha2": "FK",
        "alpha3": "FLK",
        "number": "238",
        "country_code": "500",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "FKP",
        "currency_symbol": "£",
        "display_currency": "FKP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 74,
        "name": "Micronesia, Federated States Of",
        "simplified_name": "Micronesia",
        "alpha2": "FM",
        "alpha3": "FSM",
        "number": "583",
        "country_code": "691",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Micronesia",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 75,
        "name": "Faroe Islands",
        "simplified_name": "Faroe Islands",
        "alpha2": "FO",
        "alpha3": "FRO",
        "number": "234",
        "country_code": "298",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "DKK",
        "currency_symbol": "kr",
        "display_currency": "DKK",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 76,
        "name": "France",
        "simplified_name": "France",
        "alpha2": "FR",
        "alpha3": "FRA",
        "number": "250",
        "country_code": "33",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Western Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{5}$",
            "postal_code_examples": "99999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 77,
        "name": "Gabon",
        "simplified_name": "Gabon",
        "alpha2": "GA",
        "alpha3": "GAB",
        "number": "266",
        "country_code": "241",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Middle Africa",
        "currency_code": "XAF",
        "currency_symbol": "",
        "display_currency": "XAF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 78,
        "name": "United Kingdom",
        "simplified_name": "United Kingdom",
        "alpha2": "GB",
        "alpha3": "GBR",
        "number": "826",
        "country_code": "44",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "GBP",
        "currency_symbol": "£",
        "display_currency": "£",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]? ?[0-9]{1}[a-zA-Z]{1,2}$",
            "postal_code_examples": "AA9A 9AA, A9A 9AA, A9 9AA, A99 9AA, AA9 9AA, AA99 9AA",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 79,
        "name": "Grenada",
        "simplified_name": "Grenada",
        "alpha2": "GD",
        "alpha3": "GRD",
        "number": "308",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "XCD",
        "currency_symbol": "$",
        "display_currency": "XCD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 80,
        "name": "Georgia",
        "simplified_name": "Georgia",
        "alpha2": "GE",
        "alpha3": "GEO",
        "number": "268",
        "country_code": "995",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "GEL",
        "currency_symbol": "",
        "display_currency": "GEL",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 81,
        "name": "French Guiana",
        "simplified_name": "French Guiana",
        "alpha2": "GF",
        "alpha3": "GUF",
        "number": "254",
        "country_code": "594",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 82,
        "name": "Guernsey",
        "simplified_name": "Guernsey",
        "alpha2": "GG",
        "alpha3": "GGY",
        "number": "831",
        "country_code": "44",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "GBP",
        "currency_symbol": "£",
        "display_currency": "£",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 83,
        "name": "Ghana",
        "simplified_name": "Ghana",
        "alpha2": "GH",
        "alpha3": "GHA",
        "number": "288",
        "country_code": "233",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "GHS",
        "currency_symbol": "¢",
        "display_currency": "GHS",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 84,
        "name": "Gibraltar",
        "simplified_name": "Gibraltar",
        "alpha2": "GI",
        "alpha3": "GIB",
        "number": "292",
        "country_code": "350",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "GIP",
        "currency_symbol": "£",
        "display_currency": "GIP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 85,
        "name": "Greenland",
        "simplified_name": "Greenland",
        "alpha2": "GL",
        "alpha3": "GRL",
        "number": "304",
        "country_code": "299",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Northern America",
        "currency_code": "DKK",
        "currency_symbol": "kr",
        "display_currency": "DKK",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 86,
        "name": "Gambia",
        "simplified_name": "Gambia",
        "alpha2": "GM",
        "alpha3": "GMB",
        "number": "270",
        "country_code": "220",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "GMD",
        "currency_symbol": "",
        "display_currency": "GMD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 87,
        "name": "Guinea",
        "simplified_name": "Guinea",
        "alpha2": "GN",
        "alpha3": "GIN",
        "number": "324",
        "country_code": "224",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "GNF",
        "currency_symbol": "",
        "display_currency": "GNF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 88,
        "name": "Guadeloupe",
        "simplified_name": "Guadeloupe",
        "alpha2": "GP",
        "alpha3": "GLP",
        "number": "312",
        "country_code": "590",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 89,
        "name": "Equatorial Guinea",
        "simplified_name": "Equatorial Guinea",
        "alpha2": "GQ",
        "alpha3": "GNQ",
        "number": "226",
        "country_code": "240",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Middle Africa",
        "currency_code": "",
        "currency_symbol": "",
        "display_currency": "",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 90,
        "name": "Greece",
        "simplified_name": "Greece",
        "alpha2": "GR",
        "alpha3": "GRC",
        "number": "300",
        "country_code": "30",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 91,
        "name": "South Georgia and the South Sandwich Islands",
        "simplified_name": "South Georgia and the South Sandwich Islands",
        "alpha2": "GS",
        "alpha3": "SGS",
        "number": "239",
        "country_code": "500",
        "continent": "Others",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "",
        "currency_symbol": "",
        "display_currency": "",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 92,
        "name": "Guatemala",
        "simplified_name": "Guatemala",
        "alpha2": "GT",
        "alpha3": "GTM",
        "number": "320",
        "country_code": "502",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Central America",
        "currency_code": "GTQ",
        "currency_symbol": "Q",
        "display_currency": "GTQ",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 93,
        "name": "Guam",
        "simplified_name": "Guam",
        "alpha2": "GU",
        "alpha3": "GUM",
        "number": "316",
        "country_code": "1",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Micronesia",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 94,
        "name": "Guinea-Bissau",
        "simplified_name": "Guinea-Bissau",
        "alpha2": "GW",
        "alpha3": "GNB",
        "number": "624",
        "country_code": "245",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "XOF",
        "currency_symbol": "",
        "display_currency": "XOF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 95,
        "name": "Guyana",
        "simplified_name": "Guyana",
        "alpha2": "GY",
        "alpha3": "GUY",
        "number": "328",
        "country_code": "592",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "GYD",
        "currency_symbol": "",
        "display_currency": "GYD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 96,
        "name": "Hong Kong",
        "simplified_name": "Hong Kong",
        "alpha2": "HK",
        "alpha3": "HKG",
        "number": "344",
        "country_code": "852",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "currency_code": "HKD",
        "currency_symbol": "$",
        "display_currency": "HKD",
        "requirements": {
            "requires_state": true,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 97,
        "name": "Heard and McDonald Islands",
        "simplified_name": "Heard and McDonald Islands",
        "alpha2": "HM",
        "alpha3": "HMD",
        "number": "334",
        "country_code": "",
        "continent": "Others",
        "region": "",
        "subregion": "",
        "currency_code": "AUD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 98,
        "name": "Honduras",
        "simplified_name": "Honduras",
        "alpha2": "HN",
        "alpha3": "HND",
        "number": "340",
        "country_code": "504",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Central America",
        "currency_code": "HNL",
        "currency_symbol": "L",
        "display_currency": "HNL",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 99,
        "name": "Croatia",
        "simplified_name": "Croatia",
        "alpha2": "HR",
        "alpha3": "HRV",
        "number": "191",
        "country_code": "385",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "HRK",
        "currency_symbol": "kn",
        "display_currency": "HRK",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 100,
        "name": "Haiti",
        "simplified_name": "Haiti",
        "alpha2": "HT",
        "alpha3": "HTI",
        "number": "332",
        "country_code": "509",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 101,
        "name": "Hungary",
        "simplified_name": "Hungary",
        "alpha2": "HU",
        "alpha3": "HUN",
        "number": "348",
        "country_code": "36",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "currency_code": "HUF",
        "currency_symbol": "Ft",
        "display_currency": "HUF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 102,
        "name": "Indonesia",
        "simplified_name": "Indonesia",
        "alpha2": "ID",
        "alpha3": "IDN",
        "number": "360",
        "country_code": "62",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "currency_code": "IDR",
        "currency_symbol": "Rp",
        "display_currency": "IDR",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{5}$",
            "postal_code_examples": "99999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": true,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 103,
        "name": "Ireland",
        "simplified_name": "Ireland",
        "alpha2": "IE",
        "alpha3": "IRL",
        "number": "372",
        "country_code": "353",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 104,
        "name": "Israel",
        "simplified_name": "Israel",
        "alpha2": "IL",
        "alpha3": "ISR",
        "number": "376",
        "country_code": "972",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "ILS",
        "currency_symbol": "₪",
        "display_currency": "ILS",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 105,
        "name": "Isle of Man",
        "simplified_name": "Isle of Man",
        "alpha2": "IM",
        "alpha3": "IMN",
        "number": "833",
        "country_code": "44",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "IMP",
        "currency_symbol": "£",
        "display_currency": "IMP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 106,
        "name": "India",
        "simplified_name": "India",
        "alpha2": "IN",
        "alpha3": "IND",
        "number": "356",
        "country_code": "91",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Southern Asia",
        "currency_code": "INR",
        "currency_symbol": "₨",
        "display_currency": "INR",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 107,
        "name": "British Indian Ocean Territory",
        "simplified_name": "British Indian Ocean Territory",
        "alpha2": "IO",
        "alpha3": "IOT",
        "number": "086",
        "country_code": "246",
        "continent": "Asia",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 108,
        "name": "Iraq",
        "simplified_name": "Iraq",
        "alpha2": "IQ",
        "alpha3": "IRQ",
        "number": "368",
        "country_code": "964",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "IQD",
        "currency_symbol": "",
        "display_currency": "IQD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 109,
        "name": "Iran, Islamic Republic Of",
        "simplified_name": "Iran",
        "alpha2": "IR",
        "alpha3": "IRN",
        "number": "364",
        "country_code": "98",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Southern Asia",
        "currency_code": "IRR",
        "currency_symbol": "﷼",
        "display_currency": "IRR",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 110,
        "name": "Iceland",
        "simplified_name": "Iceland",
        "alpha2": "IS",
        "alpha3": "ISL",
        "number": "352",
        "country_code": "354",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "ISK",
        "currency_symbol": "kr",
        "display_currency": "ISK",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 111,
        "name": "Italy",
        "simplified_name": "Italy",
        "alpha2": "IT",
        "alpha3": "ITA",
        "number": "380",
        "country_code": "39",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{5}$",
            "postal_code_examples": "99999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 112,
        "name": "Jersey",
        "simplified_name": "Jersey",
        "alpha2": "JE",
        "alpha3": "JEY",
        "number": "832",
        "country_code": "44",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "JEP",
        "currency_symbol": "£",
        "display_currency": "JEP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 113,
        "name": "Jamaica",
        "simplified_name": "Jamaica",
        "alpha2": "JM",
        "alpha3": "JAM",
        "number": "388",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "JMD",
        "currency_symbol": "",
        "display_currency": "JMD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 114,
        "name": "Jordan",
        "simplified_name": "Jordan",
        "alpha2": "JO",
        "alpha3": "JOR",
        "number": "400",
        "country_code": "962",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "JOD",
        "currency_symbol": "",
        "display_currency": "JOD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 115,
        "name": "Japan",
        "simplified_name": "Japan",
        "alpha2": "JP",
        "alpha3": "JPN",
        "number": "392",
        "country_code": "81",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "currency_code": "JPY",
        "currency_symbol": "¥",
        "display_currency": "JPY",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^([0-9]){7}$",
            "postal_code_examples": "9999999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 116,
        "name": "Kenya",
        "simplified_name": "Kenya",
        "alpha2": "KE",
        "alpha3": "KEN",
        "number": "404",
        "country_code": "254",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "KES",
        "currency_symbol": "",
        "display_currency": "KES",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 117,
        "name": "Kyrgyzstan",
        "simplified_name": "Kyrgyzstan",
        "alpha2": "KG",
        "alpha3": "KGZ",
        "number": "417",
        "country_code": "996",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Central Asia",
        "currency_code": "KGS",
        "currency_symbol": "лв",
        "display_currency": "KGS",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 118,
        "name": "Cambodia",
        "simplified_name": "Cambodia",
        "alpha2": "KH",
        "alpha3": "KHM",
        "number": "116",
        "country_code": "855",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "currency_code": "KHR",
        "currency_symbol": "",
        "display_currency": "KHR",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 119,
        "name": "Kiribati",
        "simplified_name": "Kiribati",
        "alpha2": "KI",
        "alpha3": "KIR",
        "number": "296",
        "country_code": "686",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Micronesia",
        "currency_code": "AUD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": true,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 120,
        "name": "Comoros",
        "simplified_name": "Comoros",
        "alpha2": "KM",
        "alpha3": "COM",
        "number": "174",
        "country_code": "269",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "KMF",
        "currency_symbol": "",
        "display_currency": "KMF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 121,
        "name": "Saint Kitts And Nevis",
        "simplified_name": "Saint Kitts And Nevis",
        "alpha2": "KN",
        "alpha3": "KNA",
        "number": "659",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "XCD",
        "currency_symbol": "$",
        "display_currency": "XCD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 122,
        "name": "Korea, Democratic People's Republic Of",
        "simplified_name": "North Korea",
        "alpha2": "KP",
        "alpha3": "PRK",
        "number": "408",
        "country_code": "850",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "currency_code": "KPW",
        "currency_symbol": "₩",
        "display_currency": "KPW",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 123,
        "name": "Korea, Republic of",
        "simplified_name": "South Korea",
        "alpha2": "KR",
        "alpha3": "KOR",
        "number": "410",
        "country_code": "82",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "currency_code": "KRW",
        "currency_symbol": "₩",
        "display_currency": "KRW",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": true,
            "consignee_tax_id_required_umbrella_names": [
                "SFExpress",
                "Aramex"
            ]
        }
    },
    {
        "id": 124,
        "name": "Kuwait",
        "simplified_name": "Kuwait",
        "alpha2": "KW",
        "alpha3": "KWT",
        "number": "414",
        "country_code": "965",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "KWD",
        "currency_symbol": "",
        "display_currency": "KWD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 125,
        "name": "Cayman Islands",
        "simplified_name": "Cayman Islands",
        "alpha2": "KY",
        "alpha3": "CYM",
        "number": "136",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "KYD",
        "currency_symbol": "$",
        "display_currency": "KYD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 126,
        "name": "Kazakhstan",
        "simplified_name": "Kazakhstan",
        "alpha2": "KZ",
        "alpha3": "KAZ",
        "number": "398",
        "country_code": "7",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Central Asia",
        "currency_code": "KZT",
        "currency_symbol": "лв",
        "display_currency": "KZT",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 127,
        "name": "Lao People's Democratic Republic",
        "simplified_name": "Laos",
        "alpha2": "LA",
        "alpha3": "LAO",
        "number": "418",
        "country_code": "856",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "currency_code": "LAK",
        "currency_symbol": "₭",
        "display_currency": "LAK",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 128,
        "name": "Lebanon",
        "simplified_name": "Lebanon",
        "alpha2": "LB",
        "alpha3": "LBN",
        "number": "422",
        "country_code": "961",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "LBP",
        "currency_symbol": "£",
        "display_currency": "LBP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 129,
        "name": "Saint Lucia",
        "simplified_name": "Saint Lucia",
        "alpha2": "LC",
        "alpha3": "LCA",
        "number": "662",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "XCD",
        "currency_symbol": "$",
        "display_currency": "XCD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 130,
        "name": "Liechtenstein",
        "simplified_name": "Liechtenstein",
        "alpha2": "LI",
        "alpha3": "LIE",
        "number": "438",
        "country_code": "423",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Western Europe",
        "currency_code": "CHF",
        "currency_symbol": "CHF",
        "display_currency": "CHF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 131,
        "name": "Sri Lanka",
        "simplified_name": "Sri Lanka",
        "alpha2": "LK",
        "alpha3": "LKA",
        "number": "144",
        "country_code": "94",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Southern Asia",
        "currency_code": "LKR",
        "currency_symbol": "₨",
        "display_currency": "LKR",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 132,
        "name": "Liberia",
        "simplified_name": "Liberia",
        "alpha2": "LR",
        "alpha3": "LBR",
        "number": "430",
        "country_code": "231",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "LRD",
        "currency_symbol": "$",
        "display_currency": "LRD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 133,
        "name": "Lesotho",
        "simplified_name": "Lesotho",
        "alpha2": "LS",
        "alpha3": "LSO",
        "number": "426",
        "country_code": "266",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Southern Africa",
        "currency_code": "LSL",
        "currency_symbol": "",
        "display_currency": "LSL",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 134,
        "name": "Lithuania",
        "simplified_name": "Lithuania",
        "alpha2": "LT",
        "alpha3": "LTU",
        "number": "440",
        "country_code": "370",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 135,
        "name": "Luxembourg",
        "simplified_name": "Luxembourg",
        "alpha2": "LU",
        "alpha3": "LUX",
        "number": "442",
        "country_code": "352",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Western Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 136,
        "name": "Latvia",
        "simplified_name": "Latvia",
        "alpha2": "LV",
        "alpha3": "LVA",
        "number": "428",
        "country_code": "371",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 137,
        "name": "Libya",
        "simplified_name": "Libya",
        "alpha2": "LY",
        "alpha3": "LBY",
        "number": "434",
        "country_code": "218",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Northern Africa",
        "currency_code": "LYD",
        "currency_symbol": "",
        "display_currency": "LYD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 138,
        "name": "Morocco",
        "simplified_name": "Morocco",
        "alpha2": "MA",
        "alpha3": "MAR",
        "number": "504",
        "country_code": "212",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Northern Africa",
        "currency_code": "MAD",
        "currency_symbol": "",
        "display_currency": "MAD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 139,
        "name": "Monaco",
        "simplified_name": "Monaco",
        "alpha2": "MC",
        "alpha3": "MCO",
        "number": "492",
        "country_code": "377",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Western Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 140,
        "name": "Moldova, Republic of",
        "simplified_name": "Moldova",
        "alpha2": "MD",
        "alpha3": "MDA",
        "number": "498",
        "country_code": "373",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "currency_code": "MDL",
        "currency_symbol": "",
        "display_currency": "MDL",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 141,
        "name": "Montenegro",
        "simplified_name": "Montenegro",
        "alpha2": "ME",
        "alpha3": "MNE",
        "number": "499",
        "country_code": "382",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 142,
        "name": "Saint Martin",
        "simplified_name": "Saint Martin",
        "alpha2": "MF",
        "alpha3": "MAF",
        "number": "663",
        "country_code": "590",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 143,
        "name": "Madagascar",
        "simplified_name": "Madagascar",
        "alpha2": "MG",
        "alpha3": "MDG",
        "number": "450",
        "country_code": "261",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "",
        "currency_symbol": "",
        "display_currency": "",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 144,
        "name": "Marshall Islands",
        "simplified_name": "Marshall Islands",
        "alpha2": "MH",
        "alpha3": "MHL",
        "number": "584",
        "country_code": "692",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Micronesia",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 145,
        "name": "Republic of North Macedonia",
        "simplified_name": "North Macedonia",
        "alpha2": "MK",
        "alpha3": "MKD",
        "number": "807",
        "country_code": "389",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "MKD",
        "currency_symbol": "",
        "display_currency": "MKD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 146,
        "name": "Mali",
        "simplified_name": "Mali",
        "alpha2": "ML",
        "alpha3": "MLI",
        "number": "466",
        "country_code": "223",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "XOF",
        "currency_symbol": "",
        "display_currency": "XOF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 147,
        "name": "Myanmar",
        "simplified_name": "Myanmar",
        "alpha2": "MM",
        "alpha3": "MMR",
        "number": "104",
        "country_code": "95",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "currency_code": "MMK",
        "currency_symbol": "",
        "display_currency": "MMK",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 148,
        "name": "Mongolia",
        "simplified_name": "Mongolia",
        "alpha2": "MN",
        "alpha3": "MNG",
        "number": "496",
        "country_code": "976",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "currency_code": "MNT",
        "currency_symbol": "₮",
        "display_currency": "MNT",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 149,
        "name": "Macao",
        "simplified_name": "Macao",
        "alpha2": "MO",
        "alpha3": "MAC",
        "number": "446",
        "country_code": "853",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "currency_code": "MOP",
        "currency_symbol": "",
        "display_currency": "MOP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 150,
        "name": "Northern Mariana Islands",
        "simplified_name": "Northern Mariana Islands",
        "alpha2": "MP",
        "alpha3": "MNP",
        "number": "580",
        "country_code": "1",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Micronesia",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 151,
        "name": "Martinique",
        "simplified_name": "Martinique",
        "alpha2": "MQ",
        "alpha3": "MTQ",
        "number": "474",
        "country_code": "596",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 152,
        "name": "Mauritania",
        "simplified_name": "Mauritania",
        "alpha2": "MR",
        "alpha3": "MRT",
        "number": "478",
        "country_code": "222",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "MRO",
        "currency_symbol": "",
        "display_currency": "MRO",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 153,
        "name": "Montserrat",
        "simplified_name": "Montserrat",
        "alpha2": "MS",
        "alpha3": "MSR",
        "number": "500",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "XCD",
        "currency_symbol": "$",
        "display_currency": "XCD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 154,
        "name": "Malta",
        "simplified_name": "Malta",
        "alpha2": "MT",
        "alpha3": "MLT",
        "number": "470",
        "country_code": "356",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 155,
        "name": "Mauritius",
        "simplified_name": "Mauritius",
        "alpha2": "MU",
        "alpha3": "MUS",
        "number": "480",
        "country_code": "230",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "MUR",
        "currency_symbol": "₨",
        "display_currency": "MUR",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 156,
        "name": "Maldives",
        "simplified_name": "Maldives",
        "alpha2": "MV",
        "alpha3": "MDV",
        "number": "462",
        "country_code": "960",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Southern Asia",
        "currency_code": "MVR",
        "currency_symbol": "",
        "display_currency": "MVR",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 157,
        "name": "Malawi",
        "simplified_name": "Malawi",
        "alpha2": "MW",
        "alpha3": "MWI",
        "number": "454",
        "country_code": "265",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "MWK",
        "currency_symbol": "",
        "display_currency": "MWK",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 158,
        "name": "Mexico",
        "simplified_name": "Mexico",
        "alpha2": "MX",
        "alpha3": "MEX",
        "number": "484",
        "country_code": "52",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Central America",
        "currency_code": "MXN",
        "currency_symbol": "$",
        "display_currency": "MXN",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{5}$",
            "postal_code_examples": "99999",
            "postal_code_mandatory_from_origin": [
                234
            ],
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 159,
        "name": "Malaysia",
        "simplified_name": "Malaysia",
        "alpha2": "MY",
        "alpha3": "MYS",
        "number": "458",
        "country_code": "60",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "currency_code": "MYR",
        "currency_symbol": "RM",
        "display_currency": "MYR",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{5}$",
            "postal_code_examples": "99999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 160,
        "name": "Mozambique",
        "simplified_name": "Mozambique",
        "alpha2": "MZ",
        "alpha3": "MOZ",
        "number": "508",
        "country_code": "258",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "MZN",
        "currency_symbol": "MT",
        "display_currency": "MZN",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 161,
        "name": "Namibia",
        "simplified_name": "Namibia",
        "alpha2": "NA",
        "alpha3": "NAM",
        "number": "516",
        "country_code": "264",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Southern Africa",
        "currency_code": "NAD",
        "currency_symbol": "$",
        "display_currency": "NAD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 162,
        "name": "New Caledonia",
        "simplified_name": "New Caledonia",
        "alpha2": "NC",
        "alpha3": "NCL",
        "number": "540",
        "country_code": "687",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Melanesia",
        "currency_code": "XPF",
        "currency_symbol": "",
        "display_currency": "XPF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 163,
        "name": "Niger",
        "simplified_name": "Niger",
        "alpha2": "NE",
        "alpha3": "NER",
        "number": "562",
        "country_code": "227",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "XOF",
        "currency_symbol": "",
        "display_currency": "XOF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 164,
        "name": "Norfolk Island",
        "simplified_name": "Norfolk Island",
        "alpha2": "NF",
        "alpha3": "NFK",
        "number": "574",
        "country_code": "672",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "currency_code": "AUD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 165,
        "name": "Nigeria",
        "simplified_name": "Nigeria",
        "alpha2": "NG",
        "alpha3": "NGA",
        "number": "566",
        "country_code": "234",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "NGN",
        "currency_symbol": "₦",
        "display_currency": "NGN",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 166,
        "name": "Nicaragua",
        "simplified_name": "Nicaragua",
        "alpha2": "NI",
        "alpha3": "NIC",
        "number": "558",
        "country_code": "505",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Central America",
        "currency_code": "NIO",
        "currency_symbol": "C$",
        "display_currency": "NIO",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 167,
        "name": "Netherlands",
        "simplified_name": "Netherlands",
        "alpha2": "NL",
        "alpha3": "NLD",
        "number": "528",
        "country_code": "31",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Western Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{4} ?[A-Z]{2}$",
            "postal_code_examples": "9999 AA, 9999AA",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 168,
        "name": "Norway",
        "simplified_name": "Norway",
        "alpha2": "NO",
        "alpha3": "NOR",
        "number": "578",
        "country_code": "47",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "NOK",
        "currency_symbol": "kr",
        "display_currency": "NOK",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{4}$",
            "postal_code_examples": "9999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 169,
        "name": "Nepal",
        "simplified_name": "Nepal",
        "alpha2": "NP",
        "alpha3": "NPL",
        "number": "524",
        "country_code": "977",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Southern Asia",
        "currency_code": "NPR",
        "currency_symbol": "₨",
        "display_currency": "NPR",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 170,
        "name": "Nauru",
        "simplified_name": "Nauru",
        "alpha2": "NR",
        "alpha3": "NRU",
        "number": "520",
        "country_code": "674",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Micronesia",
        "currency_code": "AUD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 171,
        "name": "Niue",
        "simplified_name": "Niue",
        "alpha2": "NU",
        "alpha3": "NIU",
        "number": "570",
        "country_code": "683",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Polynesia",
        "currency_code": "",
        "currency_symbol": "",
        "display_currency": "",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 172,
        "name": "New Zealand",
        "simplified_name": "New Zealand",
        "alpha2": "NZ",
        "alpha3": "NZL",
        "number": "554",
        "country_code": "64",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "currency_code": "NZD",
        "currency_symbol": "$",
        "display_currency": "NZD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{4}$",
            "postal_code_examples": "9999",
            "postal_code_mandatory_from_origin": [
                2,
                14,
                39,
                49,
                78,
                96,
                102,
                167,
                178,
                199,
                219,
                234,
                242,
                104,
                229,
                111,
                6,
                18,
                23,
                56,
                57,
                76,
                58,
                21,
                65,
                69,
                90,
                99,
                101,
                110,
                134,
                135,
                136,
                154,
                191,
                201,
                203,
                231,
                185,
                13,
                168,
                180,
                44,
                103,
                190,
                60,
                198,
                71,
                226,
                159,
                115,
                172,
                106,
                123,
                158
            ],
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 173,
        "name": "Oman",
        "simplified_name": "Oman",
        "alpha2": "OM",
        "alpha3": "OMN",
        "number": "512",
        "country_code": "968",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "OMR",
        "currency_symbol": "﷼",
        "display_currency": "OMR",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 174,
        "name": "Panama",
        "simplified_name": "Panama",
        "alpha2": "PA",
        "alpha3": "PAN",
        "number": "591",
        "country_code": "507",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Central America",
        "currency_code": "PAB",
        "currency_symbol": "B/.",
        "display_currency": "PAB",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 175,
        "name": "Peru",
        "simplified_name": "Peru",
        "alpha2": "PE",
        "alpha3": "PER",
        "number": "604",
        "country_code": "51",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "PEN",
        "currency_symbol": "S/.",
        "display_currency": "PEN",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 176,
        "name": "French Polynesia",
        "simplified_name": "French Polynesia",
        "alpha2": "PF",
        "alpha3": "PYF",
        "number": "258",
        "country_code": "689",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Polynesia",
        "currency_code": "XPF",
        "currency_symbol": "",
        "display_currency": "XPF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 177,
        "name": "Papua New Guinea",
        "simplified_name": "Papua New Guinea",
        "alpha2": "PG",
        "alpha3": "PNG",
        "number": "598",
        "country_code": "675",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Melanesia",
        "currency_code": "PGK",
        "currency_symbol": "",
        "display_currency": "PGK",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 178,
        "name": "Philippines",
        "simplified_name": "Philippines",
        "alpha2": "PH",
        "alpha3": "PHL",
        "number": "608",
        "country_code": "63",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "currency_code": "PHP",
        "currency_symbol": "Php",
        "display_currency": "PHP",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 179,
        "name": "Pakistan",
        "simplified_name": "Pakistan",
        "alpha2": "PK",
        "alpha3": "PAK",
        "number": "586",
        "country_code": "92",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Southern Asia",
        "currency_code": "PKR",
        "currency_symbol": "₨",
        "display_currency": "PKR",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 180,
        "name": "Poland",
        "simplified_name": "Poland",
        "alpha2": "PL",
        "alpha3": "POL",
        "number": "616",
        "country_code": "48",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "currency_code": "PLN",
        "currency_symbol": "zł",
        "display_currency": "PLN",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 181,
        "name": "Saint Pierre And Miquelon",
        "simplified_name": "Saint Pierre And Miquelon",
        "alpha2": "PM",
        "alpha3": "SPM",
        "number": "666",
        "country_code": "508",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Northern America",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 182,
        "name": "Pitcairn",
        "simplified_name": "Pitcairn",
        "alpha2": "PN",
        "alpha3": "PCN",
        "number": "612",
        "country_code": "",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Polynesia",
        "currency_code": "NZD",
        "currency_symbol": "$",
        "display_currency": "NZD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 183,
        "name": "Puerto Rico",
        "simplified_name": "Puerto Rico",
        "alpha2": "PR",
        "alpha3": "PRI",
        "number": "630",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{5}$",
            "postal_code_examples": "99999",
            "postal_code_mandatory_from_origin": [
                234
            ],
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 184,
        "name": "Palestine, State of",
        "simplified_name": "Palestine",
        "alpha2": "PS",
        "alpha3": "PSE",
        "number": "275",
        "country_code": "970",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "",
        "currency_symbol": "",
        "display_currency": "",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 185,
        "name": "Portugal",
        "simplified_name": "Portugal",
        "alpha2": "PT",
        "alpha3": "PRT",
        "number": "620",
        "country_code": "351",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 186,
        "name": "Palau",
        "simplified_name": "Palau",
        "alpha2": "PW",
        "alpha3": "PLW",
        "number": "585",
        "country_code": "680",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Micronesia",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 187,
        "name": "Paraguay",
        "simplified_name": "Paraguay",
        "alpha2": "PY",
        "alpha3": "PRY",
        "number": "600",
        "country_code": "595",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "PYG",
        "currency_symbol": "Gs",
        "display_currency": "PYG",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 188,
        "name": "Qatar",
        "simplified_name": "Qatar",
        "alpha2": "QA",
        "alpha3": "QAT",
        "number": "634",
        "country_code": "974",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "QAR",
        "currency_symbol": "﷼",
        "display_currency": "QAR",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 189,
        "name": "Réunion",
        "simplified_name": "Réunion",
        "alpha2": "RE",
        "alpha3": "REU",
        "number": "638",
        "country_code": "262",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 190,
        "name": "Romania",
        "simplified_name": "Romania",
        "alpha2": "RO",
        "alpha3": "ROU",
        "number": "642",
        "country_code": "40",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "currency_code": "RON",
        "currency_symbol": "lei",
        "display_currency": "RON",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 191,
        "name": "Serbia",
        "simplified_name": "Serbia",
        "alpha2": "RS",
        "alpha3": "SRB",
        "number": "688",
        "country_code": "381",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "RSD",
        "currency_symbol": "Дин.",
        "display_currency": "RSD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 192,
        "name": "Russian Federation",
        "simplified_name": "Russia",
        "alpha2": "RU",
        "alpha3": "RUS",
        "number": "643",
        "country_code": "7",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "currency_code": "RUB",
        "currency_symbol": "руб",
        "display_currency": "RUB",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 193,
        "name": "Rwanda",
        "simplified_name": "Rwanda",
        "alpha2": "RW",
        "alpha3": "RWA",
        "number": "646",
        "country_code": "250",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "RWF",
        "currency_symbol": "",
        "display_currency": "RWF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 194,
        "name": "Saudi Arabia",
        "simplified_name": "Saudi Arabia",
        "alpha2": "SA",
        "alpha3": "SAU",
        "number": "682",
        "country_code": "966",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "SAR",
        "currency_symbol": "﷼",
        "display_currency": "SAR",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 195,
        "name": "Solomon Islands",
        "simplified_name": "Solomon Islands",
        "alpha2": "SB",
        "alpha3": "SLB",
        "number": "090",
        "country_code": "677",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Melanesia",
        "currency_code": "SBD",
        "currency_symbol": "$",
        "display_currency": "SBD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 196,
        "name": "Seychelles",
        "simplified_name": "Seychelles",
        "alpha2": "SC",
        "alpha3": "SYC",
        "number": "690",
        "country_code": "248",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "SCR",
        "currency_symbol": "₨",
        "display_currency": "SCR",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 197,
        "name": "Sudan",
        "simplified_name": "Sudan",
        "alpha2": "SD",
        "alpha3": "SDN",
        "number": "729",
        "country_code": "249",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Northern Africa",
        "currency_code": "SDG",
        "currency_symbol": "",
        "display_currency": "SDG",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 198,
        "name": "Sweden",
        "simplified_name": "Sweden",
        "alpha2": "SE",
        "alpha3": "SWE",
        "number": "752",
        "country_code": "46",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "SEK",
        "currency_symbol": "kr",
        "display_currency": "SEK",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{5}$",
            "postal_code_examples": "99999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 199,
        "name": "Singapore",
        "simplified_name": "Singapore",
        "alpha2": "SG",
        "alpha3": "SGP",
        "number": "702",
        "country_code": "65",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "currency_code": "SGD",
        "currency_symbol": "$",
        "display_currency": "SGD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{6}$",
            "postal_code_examples": "999999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 200,
        "name": "Saint Helena",
        "simplified_name": "Saint Helena",
        "alpha2": "SH",
        "alpha3": "SHN",
        "number": "654",
        "country_code": "290",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "SHP",
        "currency_symbol": "£",
        "display_currency": "SHP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 201,
        "name": "Slovenia",
        "simplified_name": "Slovenia",
        "alpha2": "SI",
        "alpha3": "SVN",
        "number": "705",
        "country_code": "386",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 202,
        "name": "Svalbard And Jan Mayen",
        "simplified_name": "Svalbard And Jan Mayen",
        "alpha2": "SJ",
        "alpha3": "SJM",
        "number": "744",
        "country_code": "47",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Northern Europe",
        "currency_code": "NOK",
        "currency_symbol": "kr",
        "display_currency": "NOK",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 203,
        "name": "Slovakia",
        "simplified_name": "Slovakia",
        "alpha2": "SK",
        "alpha3": "SVK",
        "number": "703",
        "country_code": "421",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 204,
        "name": "Sierra Leone",
        "simplified_name": "Sierra Leone",
        "alpha2": "SL",
        "alpha3": "SLE",
        "number": "694",
        "country_code": "232",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "SLL",
        "currency_symbol": "",
        "display_currency": "SLL",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 205,
        "name": "San Marino",
        "simplified_name": "San Marino",
        "alpha2": "SM",
        "alpha3": "SMR",
        "number": "674",
        "country_code": "378",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 206,
        "name": "Senegal",
        "simplified_name": "Senegal",
        "alpha2": "SN",
        "alpha3": "SEN",
        "number": "686",
        "country_code": "221",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "XOF",
        "currency_symbol": "",
        "display_currency": "XOF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 207,
        "name": "Somalia",
        "simplified_name": "Somalia",
        "alpha2": "SO",
        "alpha3": "SOM",
        "number": "706",
        "country_code": "252",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "SOS",
        "currency_symbol": "S",
        "display_currency": "SOS",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 208,
        "name": "Suriname",
        "simplified_name": "Suriname",
        "alpha2": "SR",
        "alpha3": "SUR",
        "number": "740",
        "country_code": "597",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "SRD",
        "currency_symbol": "$",
        "display_currency": "SRD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 209,
        "name": "South Sudan",
        "simplified_name": "South Sudan",
        "alpha2": "SS",
        "alpha3": "SSD",
        "number": "728",
        "country_code": "211",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Northern Africa",
        "currency_code": "",
        "currency_symbol": "",
        "display_currency": "",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 210,
        "name": "Sao Tome and Principe",
        "simplified_name": "Sao Tome and Principe",
        "alpha2": "ST",
        "alpha3": "STP",
        "number": "678",
        "country_code": "239",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Middle Africa",
        "currency_code": "STD",
        "currency_symbol": "",
        "display_currency": "STD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 211,
        "name": "El Salvador",
        "simplified_name": "El Salvador",
        "alpha2": "SV",
        "alpha3": "SLV",
        "number": "222",
        "country_code": "503",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Central America",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 212,
        "name": "Sint Maarten",
        "simplified_name": "Sint Maarten",
        "alpha2": "SX",
        "alpha3": "SXM",
        "number": "534",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "ANG",
        "currency_symbol": "ƒ",
        "display_currency": "ANG",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 213,
        "name": "Syrian Arab Republic",
        "simplified_name": "Syria",
        "alpha2": "SY",
        "alpha3": "SYR",
        "number": "760",
        "country_code": "963",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "SYP",
        "currency_symbol": "£",
        "display_currency": "SYP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 214,
        "name": "The Kingdom of Eswatini",
        "simplified_name": "Eswatini",
        "alpha2": "SZ",
        "alpha3": "SWZ",
        "number": "748",
        "country_code": "268",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Southern Africa",
        "currency_code": "SZL",
        "currency_symbol": "",
        "display_currency": "SZL",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 215,
        "name": "Turks and Caicos Islands",
        "simplified_name": "Turks and Caicos Islands",
        "alpha2": "TC",
        "alpha3": "TCA",
        "number": "796",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 216,
        "name": "Chad",
        "simplified_name": "Chad",
        "alpha2": "TD",
        "alpha3": "TCD",
        "number": "148",
        "country_code": "235",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Middle Africa",
        "currency_code": "XAF",
        "currency_symbol": "",
        "display_currency": "XAF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 217,
        "name": "French Southern Territories",
        "simplified_name": "French Southern Territories",
        "alpha2": "TF",
        "alpha3": "ATF",
        "number": "260",
        "country_code": "",
        "continent": "Others",
        "region": "",
        "subregion": "",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 218,
        "name": "Togo",
        "simplified_name": "Togo",
        "alpha2": "TG",
        "alpha3": "TGO",
        "number": "768",
        "country_code": "228",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Western Africa",
        "currency_code": "XOF",
        "currency_symbol": "",
        "display_currency": "XOF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 219,
        "name": "Thailand",
        "simplified_name": "Thailand",
        "alpha2": "TH",
        "alpha3": "THA",
        "number": "764",
        "country_code": "66",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "currency_code": "THB",
        "currency_symbol": "฿",
        "display_currency": "THB",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 220,
        "name": "Tajikistan",
        "simplified_name": "Tajikistan",
        "alpha2": "TJ",
        "alpha3": "TJK",
        "number": "762",
        "country_code": "992",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Central Asia",
        "currency_code": "TJS",
        "currency_symbol": "",
        "display_currency": "TJS",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 221,
        "name": "Tokelau",
        "simplified_name": "Tokelau",
        "alpha2": "TK",
        "alpha3": "TKL",
        "number": "772",
        "country_code": "690",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Polynesia",
        "currency_code": "NZD",
        "currency_symbol": "$",
        "display_currency": "NZD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 222,
        "name": "Timor-Leste",
        "simplified_name": "Timor-Leste",
        "alpha2": "TL",
        "alpha3": "TLS",
        "number": "626",
        "country_code": "670",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "currency_code": "IDR",
        "currency_symbol": "Rp",
        "display_currency": "IDR",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 223,
        "name": "Turkmenistan",
        "simplified_name": "Turkmenistan",
        "alpha2": "TM",
        "alpha3": "TKM",
        "number": "795",
        "country_code": "993",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Central Asia",
        "currency_code": "",
        "currency_symbol": "",
        "display_currency": "",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 224,
        "name": "Tunisia",
        "simplified_name": "Tunisia",
        "alpha2": "TN",
        "alpha3": "TUN",
        "number": "788",
        "country_code": "216",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Northern Africa",
        "currency_code": "TND",
        "currency_symbol": "",
        "display_currency": "TND",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 225,
        "name": "Tonga",
        "simplified_name": "Tonga",
        "alpha2": "TO",
        "alpha3": "TON",
        "number": "776",
        "country_code": "676",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Polynesia",
        "currency_code": "TOP",
        "currency_symbol": "",
        "display_currency": "TOP",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 226,
        "name": "Turkey",
        "simplified_name": "Turkey",
        "alpha2": "TR",
        "alpha3": "TUR",
        "number": "792",
        "country_code": "90",
        "continent": "Europe",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "TRY",
        "currency_symbol": "TL",
        "display_currency": "TRY",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": true,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 227,
        "name": "Trinidad and Tobago",
        "simplified_name": "Trinidad and Tobago",
        "alpha2": "TT",
        "alpha3": "TTO",
        "number": "780",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "TTD",
        "currency_symbol": "$",
        "display_currency": "TTD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 228,
        "name": "Tuvalu",
        "simplified_name": "Tuvalu",
        "alpha2": "TV",
        "alpha3": "TUV",
        "number": "798",
        "country_code": "688",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Polynesia",
        "currency_code": "TVD",
        "currency_symbol": "",
        "display_currency": "TVD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 229,
        "name": "Taiwan, Republic Of China",
        "simplified_name": "Taiwan",
        "alpha2": "TW",
        "alpha3": "TWN",
        "number": "158",
        "country_code": "886",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "currency_code": "TWD",
        "currency_symbol": "NT$",
        "display_currency": "TWD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": "^([0-9]{3}|[0-9]{5})$",
            "postal_code_examples": "999, 99999",
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": true,
            "consignee_tax_id_required_umbrella_names": [
                "Aramex",
                "Janio",
                "SFExpress"
            ]
        }
    },
    {
        "id": 230,
        "name": "Tanzania, United Republic of",
        "simplified_name": "Tanzania",
        "alpha2": "TZ",
        "alpha3": "TZA",
        "number": "834",
        "country_code": "255",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "TZS",
        "currency_symbol": "",
        "display_currency": "TZS",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 231,
        "name": "Ukraine",
        "simplified_name": "Ukraine",
        "alpha2": "UA",
        "alpha3": "UKR",
        "number": "804",
        "country_code": "380",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "currency_code": "UAH",
        "currency_symbol": "₴",
        "display_currency": "UAH",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 232,
        "name": "Uganda",
        "simplified_name": "Uganda",
        "alpha2": "UG",
        "alpha3": "UGA",
        "number": "800",
        "country_code": "256",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "UGX",
        "currency_symbol": "",
        "display_currency": "UGX",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 233,
        "name": "United States Minor Outlying Islands",
        "simplified_name": "United States Minor Outlying Islands",
        "alpha2": "UM",
        "alpha3": "UMI",
        "number": "581",
        "country_code": "",
        "continent": "Oceania",
        "region": "Americas",
        "subregion": "Northern America",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 234,
        "name": "United States",
        "simplified_name": "United States",
        "alpha2": "US",
        "alpha3": "USA",
        "number": "840",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Northern America",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": "^[0-9]{5}$",
            "postal_code_examples": "99999",
            "postal_code_mandatory_from_origin": [
                39,
                234
            ],
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 235,
        "name": "Uruguay",
        "simplified_name": "Uruguay",
        "alpha2": "UY",
        "alpha3": "URY",
        "number": "858",
        "country_code": "598",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "UYU",
        "currency_symbol": "$U",
        "display_currency": "UYU",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 236,
        "name": "Uzbekistan",
        "simplified_name": "Uzbekistan",
        "alpha2": "UZ",
        "alpha3": "UZB",
        "number": "860",
        "country_code": "998",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Central Asia",
        "currency_code": "UZS",
        "currency_symbol": "лв",
        "display_currency": "UZS",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 237,
        "name": "Holy See (Vatican City State)",
        "simplified_name": "Holy See (Vatican City State)",
        "alpha2": "VA",
        "alpha3": "VAT",
        "number": "336",
        "country_code": "39",
        "continent": "Europe",
        "region": "Europe",
        "subregion": "Southern Europe",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 238,
        "name": "Saint Vincent And The Grenedines",
        "simplified_name": "Saint Vincent And The Grenedines",
        "alpha2": "VC",
        "alpha3": "VCT",
        "number": "670",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "XCD",
        "currency_symbol": "$",
        "display_currency": "XCD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 239,
        "name": "Venezuela, Bolivarian Republic of",
        "simplified_name": "Venezuela",
        "alpha2": "VE",
        "alpha3": "VEN",
        "number": "862",
        "country_code": "58",
        "continent": "South America",
        "region": "Americas",
        "subregion": "South America",
        "currency_code": "VEF",
        "currency_symbol": "Bs",
        "display_currency": "VEF",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 240,
        "name": "Virgin Islands, British",
        "simplified_name": "British Virgin Islands",
        "alpha2": "VG",
        "alpha3": "VGB",
        "number": "092",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 241,
        "name": "Virgin Islands, U.S.",
        "simplified_name": "American Virgin Islands",
        "alpha2": "VI",
        "alpha3": "VIR",
        "number": "850",
        "country_code": "1",
        "continent": "North America",
        "region": "Americas",
        "subregion": "Caribbean",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 242,
        "name": "Vietnam",
        "simplified_name": "Vietnam",
        "alpha2": "VN",
        "alpha3": "VNM",
        "number": "704",
        "country_code": "84",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "currency_code": "VND",
        "currency_symbol": "₫",
        "display_currency": "VND",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 243,
        "name": "Vanuatu",
        "simplified_name": "Vanuatu",
        "alpha2": "VU",
        "alpha3": "VUT",
        "number": "548",
        "country_code": "678",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Melanesia",
        "currency_code": "",
        "currency_symbol": "",
        "display_currency": "",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 244,
        "name": "Wallis and Futuna",
        "simplified_name": "Wallis and Futuna",
        "alpha2": "WF",
        "alpha3": "WLF",
        "number": "876",
        "country_code": "681",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Polynesia",
        "currency_code": "XPF",
        "currency_symbol": "",
        "display_currency": "XPF",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 245,
        "name": "Samoa",
        "simplified_name": "Samoa",
        "alpha2": "WS",
        "alpha3": "WSM",
        "number": "882",
        "country_code": "685",
        "continent": "Oceania",
        "region": "Oceania",
        "subregion": "Polynesia",
        "currency_code": "USD",
        "currency_symbol": "$",
        "display_currency": "$",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 246,
        "name": "Yemen",
        "simplified_name": "Yemen",
        "alpha2": "YE",
        "alpha3": "YEM",
        "number": "887",
        "country_code": "967",
        "continent": "Asia",
        "region": "Asia",
        "subregion": "Western Asia",
        "currency_code": "YER",
        "currency_symbol": "﷼",
        "display_currency": "YER",
        "requirements": {
            "requires_state": true,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 247,
        "name": "Mayotte",
        "simplified_name": "Mayotte",
        "alpha2": "YT",
        "alpha3": "MYT",
        "number": "175",
        "country_code": "262",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "EUR",
        "currency_symbol": "€",
        "display_currency": "€",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 248,
        "name": "South Africa",
        "simplified_name": "South Africa",
        "alpha2": "ZA",
        "alpha3": "ZAF",
        "number": "710",
        "country_code": "27",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Southern Africa",
        "currency_code": "ZAR",
        "currency_symbol": "R",
        "display_currency": "ZAR",
        "requirements": {
            "requires_state": true,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 249,
        "name": "Zambia",
        "simplified_name": "Zambia",
        "alpha2": "ZM",
        "alpha3": "ZMB",
        "number": "894",
        "country_code": "260",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "ZMK",
        "currency_symbol": "",
        "display_currency": "ZMK",
        "requirements": {
            "requires_state": false,
            "has_postal_code": true,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    },
    {
        "id": 250,
        "name": "Zimbabwe",
        "simplified_name": "Zimbabwe",
        "alpha2": "ZW",
        "alpha3": "ZWE",
        "number": "716",
        "country_code": "263",
        "continent": "Africa",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "currency_code": "ZWD",
        "currency_symbol": "Z$",
        "display_currency": "ZWD",
        "requirements": {
            "requires_state": false,
            "has_postal_code": false,
            "postal_code_regex": null,
            "postal_code_examples": null,
            "postal_code_mandatory_from_origin": null,
            "consignee_tax_id_required": false,
            "consignee_tax_id_required_umbrella_names": []
        }
    }
]
export const GetCountryList =() => {
    return CourierRequirements;
}