import Countries from "@/static/Country.json";

export const getCountryWithCode = () => {
  const formattedCountries: any = {};

  Countries.forEach((country) => {
    const { name, dial_code, code } = country;
    formattedCountries[code] = `${name} (${dial_code})`;
  });

  return formattedCountries;
};

export const getPhoneCode = (countryCode: string): any => {
  let country = Countries.find((item: any) => item.code === countryCode);

  return {
    ...country
  }
};
