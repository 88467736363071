import { useCallback, useRef } from "react";

type DebouncedFunction<T extends any[]> = (...args: T) => void;

function useDebounceFunction<T extends any[]>(
  func: DebouncedFunction<T>,
  delay: number
) {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const debounceFunction = useCallback(
    (...args: T) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    },
    [func, delay]
  );

  return debounceFunction;
}

export default useDebounceFunction;
